// import

import type {LinkProps, NavLinkProps} from '@remix-run/react';

import {Link as BaseLink, NavLink as BaseNavLink} from '@remix-run/react';
import {forwardRef} from 'react';

import {isStr, uniqueItems} from '@cat/rambo';
import {env} from '~/libs/env';

// fns

export const parseLinkProps = <T extends LinkProps | NavLinkProps>(
  props: T,
): T => {
  const {target, to} = props;
  const isInternal =
    new URL(isStr(to) ? to : '', env.appUrl)
      .href.startsWith(env.appUrl);

  return {
    ...props,
    target: uniqueItems([
      ...target?.split(' ').map((t) => t.trim()) ?? [],
      isInternal ? '' : '_blank',
    ].filter(Boolean)).join(' '),
  };
};

// component

export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const parsed = parseLinkProps(props);

  return (
    <BaseLink {...parsed} ref={ref} />
  );
});

export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
  const parsed = parseLinkProps(props);

  return (
    <BaseNavLink {...parsed} ref={ref} />
  );
});
